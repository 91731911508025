import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useKeycloak } from '@fullfacing/ff-web-next-utils/keycloak'
import Bell from '@fullfacing/material-icons/dist/Bell'
import Help from '@fullfacing/material-icons/dist/Help'
import ChevronDown from '@fullfacing/material-icons/dist/ChevronDown'
import { Flex, Button, IconButton, Popup } from '@fullfacing/schoolbus'
import { useQueryState } from '@fullfacing/ff-web-next-utils/hooks'

import useContainerContext from '@lib/utils/use-container-context'
import { getHighestRoleRank, isAccountAdmin } from '@lib/utils/permissions'
import userContainer from '@lib/containers/user'
import accountContainer from '@lib/containers/account'
import useMessageCount from '@lib/utils/use-message-count'
import LinkButton from '@lib/common/buttons/link-button'
import hasValidRole from '@lib/utils/has-valid-role'
import {
  PLATFORM_MAP,
  CLASS_MANAGER_URL,
  GROUP_GLOBAL_URL,
  MODERATOR_PORTAL_URL,
  INDIVIDUAL_TRAINING_URL
} from '@lib/config/constants'

import ProfileMenu from './components/profile-menu'
import {
  Logo,
  ActiveUser,
  Header as StyledHeader,
  HeaderOptions,
  LogoContainer,
  LogoTitle,
  styleOverrides
} from './styles'

const MessageInboxModal = dynamic(() =>
  import('@lib/modals/message-inbox-modal')
)
const HelpCentreModal = dynamic(() => import('@lib/modals/help-centre-modal'))

const CLASSROOM_MANAGER = 'CM'
const MODERATOR_PORTAL = 'ModP'
const INDIVIDUAL_TRAINING = 'IT'

const CHANGE_PLATFORM_OPTIONS = [
  {
    label: 'Individual',
    href: INDIVIDUAL_TRAINING_URL,
    requiredRoles: [INDIVIDUAL_TRAINING],
    requiredLicence: ['Web', 'Mobile']
  },
  {
    label: 'Group global',
    href: GROUP_GLOBAL_URL,
    requiredLicence: ['Group']
  },
  {
    label: 'Moderator portal',
    href: MODERATOR_PORTAL_URL,
    requiredRoles: [MODERATOR_PORTAL]
  },
  {
    label: 'Classroom manager',
    href: CLASS_MANAGER_URL,
    requiredRoles: [CLASSROOM_MANAGER],
    requiredLicence: ['ClassroomManager']
  }
]

const Header = ({
  name = PLATFORM_MAP.HO,
  onLogout
}) => {
  const [, setShowInbox] = useQueryState({
    key: 'showInbox',
    initialState: 'false',
    config: { shallow: true }
  })

  const [, setShowHelpCenter] = useQueryState({
    key: 'showHelpCenter',
    initialState: 'false',
    config: { shallow: true }
  })

  const { count: unreadCount } = useMessageCount({ viewed: false })
  const { name: newName, surname: newSurname, roles } = useContainerContext(userContainer)
  const { licenses } = useContainerContext(accountContainer)
  const { keycloak } = useKeycloak()
  const { platforms = [] } = licenses

  const {
    given_name: firstName,
    family_name: surname
  } = keycloak.tokenParsed

  const nameToUse = newName || firstName
  const surnameToUse = newSurname || surname
  const fullName = `${nameToUse} ${surnameToUse}`
  const initials = `${nameToUse[0]}${surnameToUse[0]}`.toUpperCase()
  const isAccountAdminUser = isAccountAdmin()
  const cmsPlatformKey = PLATFORM_MAP[name]

  return (
    <StyledHeader>
      <Link legacyBehavior href='/' passHref>
        <LogoContainer>
          <Logo />
          <LogoTitle>{name}</LogoTitle>
        </LogoContainer>
      </Link>
      <HeaderOptions>
        <ActiveUser>{fullName}</ActiveUser>
        <Popup
          contentStyle={styleOverrides.popup}
          arrow
          position='bottomCenter'
          yOffset='.7em'
          trigger={
            <Button
              size='mini'
              variant='dark'
              p='0.4em 0.7em'
              fontSize='0.7em'
              fontWeight='extrabold'
            >
              {getHighestRoleRank().toUpperCase()}
            </Button>
          }
        >
          {(closePopup) => (
            <ProfileMenu
              platformName={name}
              userName={fullName}
              onClose={closePopup}
              onLogout={onLogout}
              initials={initials}
            />
          )}
        </Popup>
        {isAccountAdminUser && (
          <Popup
            contentStyle={styleOverrides.popup}
            arrow
            position='bottomCenter'
            yOffset='.7em'
            trigger={
              <Button
                display='flex'
                m='-0.5em 0em -0.5em 1em' // top, right, bottom, left
                size='mini'
                variant='dark'
                p='0.2em 0.4em 0.2em 0.4em'
                fontSize='0.7em'
                fontWeight='extrabold'
                alignItems='center'
              >
                Change Platform
                <ChevronDown color='inherit' size='1.7em' />
              </Button>
            }
          >
            <Flex width='100%' flexDirection='column'>
              {CHANGE_PLATFORM_OPTIONS.map(({ label, href, requiredRoles, requiredLicence }) => {
                const hasLicense = platforms?.length > 0 && requiredLicence?.every(platform => platforms.includes(platform))

                if (requiredRoles && !hasValidRole(roles, requiredRoles)) return null
                if (requiredLicence && !hasLicense) return null

                return (
                  <LinkButton
                    key={label}
                    variant='secondary'
                    shape='block'
                    mt='0.5em'
                    href={href}
                    target='_blank'
                    textAlign='center'
                  >
                    {label}
                  </LinkButton>
                )
              }).filter(Boolean)}
            </Flex>
          </Popup>
        )}
        <IconButton
          variant='dark'
          label='Inbox'
          ml='1em'
          href=''
          onClick={() => setShowInbox('true')}
          badge={unreadCount}
        >
          <Bell color='inherit' />
        </IconButton>

        <IconButton
          variant='dark'
          label='Help'
          onClick={() => setShowHelpCenter('true')}
          ml='1em'
        >
          <Help color='inherit' />
        </IconButton>

        <MessageInboxModal onClose={() => setShowInbox('false')} />

        <HelpCentreModal
          onClose={() => setShowHelpCenter('false')}
          cmsPlatformKey={cmsPlatformKey}
        />
      </HeaderOptions>
    </StyledHeader>
  )
}

export default Header
