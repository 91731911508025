import React from 'react'
import { Button, IconButton } from '@fullfacing/schoolbus'
import WithLink from '@lib/common/with-link'

const LinkButton = ({
  children,
  isIcon,
  href = '/',
  linkProps,
  target,
  ...otherProps
}) => {
  const ButtonComponent = isIcon ? IconButton : Button

  return (
    <WithLink legacyBehavior href={href} {...linkProps}>
      <ButtonComponent
        as={href ? 'a' : 'button'}
        {...otherProps}
      >
        {children}
      </ButtonComponent>
    </WithLink>
  )
}

export default LinkButton
