import Link from 'next/link'
import { Text } from '@fullfacing/schoolbus'

const WithLink = ({ href, children, target, ...otherProps }) => {
  if (!href) return children

  return target
    ? (
      <Text
        as='a'
        href={href}
        target={target}
        fontSize='inherit'
        color='unset'
        {...otherProps}
      >
        {children}
      </Text>
      )
    : (
      <Link legacyBehavior href={href} {...otherProps}>
        {children}
      </Link>
      )
}

export default WithLink
